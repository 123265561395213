<!-- students -->
<template>
  <div>
    <section-list1
      title="Students"
      :data="sectionListData"
      v-loading="loading"
     :iconButton=" ($buttonPermission('Class', 'Full') ||
                 $buttonPermission('Class', 'Edit'))"
      :notAllowed="sectionListData.length <= 0"
      @handleClick="clickStudent"
      @editStaffCallback="editStaffCallback"
	   :isAutoBack='true'
	   back='Add students in this page.'
    >
	<template slot="back">
	   <img
	     style="width: 100%"
	     :src="require('@/assets/images/moments/empty.png')"
	   />
	 </template>
    </section-list1>
    <SelectDialog
      v-model="isSelectAddShow"
      title="Add students"
      label="fullName"
      :showCheckbox="false"
	  :showSeclect='false'
	  submitText="Add"
      :data="allStudentList"
      @submit="changeAddSelect"
      @change="changeAdd"
    />
    <SelectDialog
      v-model="isSelectRemoveShow"
      title="Withdraw students"
      label="fullName"
      :showCheckbox="true"
      :showSelect="false"
	  :addStudentDes='true'
      :data="removeListData"
      submitText="Withdraw"
      @submit="changeRemoveSelect"
      @change="changeRemove"
    />
    <gg-Dialog
      :append-to-body="false"
      :visible.sync="isDialogShow"
      title="Withdraw students"
    >
      <div class="dialog-content">
        <p class="desc">
          Are you sure that you want to withdraw these students from the class?
        </p>
        <ul class="content">
          <li v-for="(item, index) in deleteData" class="item" :key="index">
            <div class="user-img">
              <head-img
                class="user-box"
                :firstName="item.firstName"
                :lastName="item.lastName"
                :src="item.avatar"
                line-height="36"
              ></head-img>
            </div>
            <div class="userInfo">
              <p class="user-name">{{ item.firstName }} {{ item.lastName }}</p>
              <p class="user-email">{{ item.email }}</p>
            </div>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="isDialogShow = false"
          >Cancel</gg-cancel-button
        >
        <gg-submit-button @click="handleRemove">Withdraw</gg-submit-button>
      </span>
    </gg-Dialog>
    <Snackbar :visible.sync="isSnackbarShow" :content="snackbarData" />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
import { lightFormat } from "date-fns";
import assign from './Assign.vue';

export default {
  name: "teachers",
  comments:{
	  assign
  },
  data() {
    return {
      query: {
        size: 20,
        current: 1,
      },
      activeIndex: "teachers",
      removeListData: [],
      sectionListData: [],
      isSelectAddShow: false,
      isSelectRemoveShow: false,
      desc: "0 enrolled students for the class size of 4",
      allStudentList: [],
      isDialogShow: false,
      selesetArr: [],
      deleteData: [],
      isSnackbarShow: false,
      snackbarData: "",
      loading: false,
	  deleteDataShow:[]
    };
  },
  computed: {
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
      user: (state) => state.user,
    }),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // let classId = to.params.id;
      // let schoolName = vm.user.schoolName;
      // vm.breadcrumbData = vm.breadcrumb.breadcrumbData;
    });
  },
  methods: {
    // 通过classId获取学生
    _getData() {
      var data = {
        id: this.user.classId,
      };
      this.loading = true;
      Ajax.get("/usermanage/classRef/selectStudentByClassIdV1", data).then(
        (res) => {
          let arr = [];
          this.sectionListData = res.data || [];
          // 赋值删除students的列表
          res.data.forEach((item) => {
            arr.push({
              id: item.id,
              avatar: item.photo,
              firstName: item.firstName,
              lastName: item.lastName,
              fullName: `${item.firstName} ${item.lastName}`,
              email: item.emailAddress,
              isUser: true,
			  tipMessage:item.tipMessage
            });
          });
          console.log("remove", this.removeListData);
          this.removeListData = arr;
          this.loading = false;
        }
      );
    },
    // 获取所有的学生列表
    _getAllStudent() {
      this.loading = true;
      //todo
      Ajax.get(
        `/usermanage/student/getStudentByLocationId/${this.user.locationId}`
      ).then((res) => {
        let response = res.data || [];
        let arr = [];
        response.forEach((item) => {
          arr.push({
            id: item.id,
            avatar: item.photo,
            firstName: item.firstName,
            lastName: item.lastName,
            fullName: `${item.firstName} ${item.lastName}`,
            email: item.emailAddress,
            isUser: true,
          });
        });
        this.allStudentList = arr;
        this.loading = false;
        console.log(this.allStudentList);
      });
    },
    menuSelect(key, item) {
      console.log(key, item);
      this.activeIndex = key;
      this.$router.push(`/${key}`);
    },
    editStaffCallback(isAddStaff) {
      this.isSelectAddShow = isAddStaff;
	  if(this.sectionListData.length <= 0){
		  return false
	  }
      this.isSelectRemoveShow = !isAddStaff;
    },
    changeAddSelect(selesetArr) {
      // this.sectionListData = this.sectionListData.concat(selesetArr);
      // let arr = [];
      // this.sectionListData.forEach((item) => {
      //   arr.push(item.id);
      // });

      let arr = [];
      selesetArr.forEach((item) => {
        arr.push(item.id);
      });

      let data = {
        classId: this.user.classId || this.$route.query.classId,
        companyId: this.user.companyId,
        locationId: this.user.locationId,
        staffIds: [],
        stuIds: arr,
        type: "active",
      };
      this.loading = true;
      Ajax.post("/usermanage/classRef/saveStudentClassRefV2", data).then(
        (res) => {
			this.isDialogShow = false;
			this.isSnackbarShow = true;
			this.snackbarData = `${arr.length==1?'':arr.length} ${arr.length>1? 'students have':'Student has'} been added`;
          this._getData();
		  this._getAllStudent();
          this.loading = false;
        }
      );
    },
    changeRemoveSelect(selesetArr) {
      this.isDialogShow = true;
      this.deleteData = selesetArr;
      console.log(this.deleteData);
    },
    changeAdd() {},
    changeRemove(selesetArr) {
		this.deleteDataShow = selesetArr
	},
    handleRemove() {
      let arr = [];
      this.deleteData.forEach((item) => {
        arr.push(item.id);
      });
      let data = {
        classId: this.user.classId,
        companyId: this.user.companyId,
        locationId: this.user.locationId,
        staffIds: [],
        stuIds: arr,
        type: "closed",
      };
      this.loading = true;
      Ajax.post("/usermanage/classRef/removeStudentClassRefV1", data).then(
        (res) => {
          this.loading = false;
          this.isDialogShow = false;
          this.isSnackbarShow = true;
          this.snackbarData = `${this.deleteData.length==1?'':this.deleteData.length} ${this.deleteData.length>1? 'students have':'Student has'} been withdrawn`;
          this._getData();
        this._getAllStudent();
		}
      );
    },
    clickStudent(item) {
      // 跳转班级老师之前设置面包屑数据
      let className = this.user.className;
      let schoolName = this.user.schoolName;
      sessionStorage.setItem(
        "studentInfo",
        JSON.stringify({
          studentId: item.id,
          type: "1",
          className,
          locationName: schoolName,
        })
      );
      this.$store.commit("SET_BREADCRUMDATA", [
        {
          routeName: "locations",
          name: schoolName,
        },
        {
          routeName: "classes",
          name: "Classes",
        },
        {
          routeName: "classDetailInfo",
          name: className,
        },
        {
          name: `${item.firstName} ${item.lastName}`,
        },
      ]);
      this.$router.push({ name: "studentsInfo" ,query:{class:true}});
    },
  },
  created() {
    this._getData();
    this._getAllStudent();
  },
};
</script>
<style lang='less' scoped>
/deep/.el-dialog__title {
  font-family: OpenSans-bold;
}
.dialog-content {
  color: #202124;
  .desc {
    margin: 18px 0 26px;
  }
  .content {
    .item {
      width: 100%;
      height: 100%;
      margin-bottom: 20px;
      padding-left: 0;
      display: flex;
      align-items: center;
      font-family: Roboto;
      line-height: 20px;
      .user-img {
        margin-right: 20px;
      }
    }
  }
}
/deep/.el-dialog{
	width: 580px;
}
/deep/.dialog-content .desc{
	font-family: Roboto;
}
/deep/.md-ripple-leave-active{
	display: none!important;
}
</style>
