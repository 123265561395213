<template>
  <gg-dialog :visible.sync="_visible" :title="title" width="684px" top="5vh">
    <div class="students_chips">
      <gg-chips
        v-model="selTeacher"
        :md-placeholder="selTeacher.length ? '' : $t('class-assignTeacher-chip')"
        md-check-duplicated
        :filter.sync="filterName"
      >
        <div slot-scope="scope" slot="md-chip" class="chip_student">
          <head-img
            class="imgBox"
            :src="scope.chip.avatar"
            :firstName="scope.chip.firstName"
            :lastName="scope.chip.lastName"
            line-height="24"
          />
          {{ scope.chip.fullName }}
        </div>
      </gg-chips>
    </div>
    <p class="list_title">
      {{ !selTeacher.length ? 'Search results' : '&nbsp;' }}
    </p>
    <div class="student_list">
      <ul v-show="!selTeacher.length">
        <li v-if="!showTeacherList.length" class="no_result">
          <i class="iconfont iconyiquxiao" />
          {{ $t('class-assignTeacher-NoResults') }}
        </li>
        <li v-for="item in showTeacherList" :key="item.id" @click="selStudent(item)">
          <head-img
            class="imgBox"
            :src="item.avatar"
            :firstName="item.firstName"
            :lastName="item.lastName"
            line-height="36"
          />
          <div class="info">
            <p class="name">{{ item.fullName }}</p>
            <p class="email">{{ item.email || '-' }}</p>
          </div>
        </li>
      </ul>
    </div>
    <span slot="footer" class="dialog-footer">
      <gg-cancel-button @click="_visible = false">{{
        $t('common-action-Cancel')
      }}</gg-cancel-button>
      <gg-submit-button
        type="primary"
        @click="submit"
        :disabled="!selTeacher.length || isSameTeacher"
      >
        {{ submitButtonText }}
      </gg-submit-button>
    </span>
  </gg-dialog>
</template>

<script>
import { isEqual } from 'lodash';
// import { getHelpDeskStaffUser, assignCvsStaff } from '@/service/helpDesk';

export default {
  props: {
    visible: {
      type: Boolean,
      dafault: false,
    },
    CvsId: {
      type: [String, Number],
      default: '',
    },
    defaultUser: {
      type: [String, Number],
      default: '',
    },
	selTeacher: {
	  type: Array,
	  default: [],
	},
  },
  data() {
    return {
      // selTeacher: [],
      filterName: '',
      teacherList: [],
    };
  },
  computed: {
    // eslint-disable-next-line valid-jsdoc
    /**
     * 根据查询的名字，过滤显示的老师
     */
    showTeacherList() {
      const _filterName = (this.filterName || '').toLowerCase();
      return this.teacherList
        .filter((v) => !this.selTeacher.find((val) => val === v))
        .filter(
          (v) =>
            ~(v.fullName || '').toLowerCase().indexOf(_filterName) ||
            ~(v.email || '').toLowerCase().indexOf(_filterName),
        );
    },
    /**
     * 控制dialog的显隐
     */
    _visible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      },
    },
    title() {
      return 'Assign teammate';
    },
    submitButtonText() {
      return 'Assign';
    },
    isSameTeacher() {
      let def = [];
      if (this.defaultUser) {
        const d = this.teacherList.find((v) => v.id === this.defaultUser);
        if (d) {
          def = [d];
        }
      }
      return isEqual(this.selTeacher, def);
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      // getHelpDeskStaffUser().then((res) => {
      //   const { code, data } = res;
      //   if (code === 200 && data) {
      //     this.teacherList = data;
      //     if (this.defaultUser) {
      //       const _sel = this.teacherList.find((v) => v.id === this.defaultUser);
      //       this.selTeacher = _sel ? [_sel] : [];
      //     }
      //   }
      // });
    },
    /**
     * 提交assign，成功之后，隐藏dialog，并且打印提示信息，告诉父组件需要更新列表
     * 根据type一级defaultUser来判断调用的api以及提示信息等
     */
    submit() {
      console.log('this.selTeacher === ', this.selTeacher);
      const param = {
        assignId: this.selTeacher[0].id,
        conversationId: this.CvsId,
      };
      assignCvsStaff(param).then((res) => {
        this._visible = false;
        this.$emit('submit');
        this.$ggMessage(`Conversation has been assigned to ${this.selTeacher[0].fullName}`);
      });
    },
    /**
     * 选中老师后则push到选中列表
     */
    selStudent(item) {
      this.selTeacher.push(item);
      this.filterName = '';
    },
  },
};
</script>

<style lang="less">
.md-field .md-input {
  font-family: Roboto;
}
</style>
<style lang="less" scoped>
.students_chips {
  h5 {
    font-size: 16px;
    font-family: OpenSans;
    font-weight: 600;
    color: rgba(32, 33, 36, 1);
  }
  /deep/ .gg_chips {
    font-family: Roboto !important;
    &.error::after {
      background: #b00020;
    }
    margin-bottom: 6px;
    .gg_chip {
      > .md-ripple {
        padding: 4px;
        padding-right: 32px;
        line-height: 24px;
        .chip_student {
          font-family: Roboto;
          display: flex;
          .user_avatar {
            margin-right: 4px;
            .textPhoto {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.imgBox {
  overflow: hidden;
  border-radius: 50%;
}
.prompt {
  color: rgba(32, 33, 36, 0.6);
  font-size: 12px;
  font-family: Roboto;
  line-height: 16px;
  margin-bottom: 28px;
  &.error {
    color: #b00020;
  }
}
.list_title {
  color: #202124;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 7px;
}
.student_list {
  height: 410px;
  overflow: auto;
  li {
    padding: 10px 0;
    height: 56px;
    cursor: pointer;
    color: #202124;
    display: flex;
    &:hover {
      background: #eeeeee;
    }
    .user_avatar {
      margin-right: 20px;
    }
    > .info {
      .name {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 600;
      }
      .email {
        font-size: 12px;
      }
    }
    &.no_result {
      cursor: auto;
      line-height: 36px;
      font-size: 16px;
      color: #202124;
      font-family: Roboto;
      &:hover {
        background: transparent;
      }
      .iconfont {
        width: 36px;
        height: 36px;
        font-size: 20px;
        border-radius: 50%;
        background: #e0e0e0;
        text-align: center;
        line-height: 36px;
        margin-right: 20px;
      }
    }
  }
}
.enrolled_student_list {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  height: 135px;
  overflow: auto;
  > li {
    display: flex;
    line-height: 36px;
    margin-right: 32px;
    margin-bottom: 10px;
    > .user_avatar {
      margin-right: 8px;
    }
  }
}
</style>
