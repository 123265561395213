import { render, staticRenderFns } from "./studentAttendance.vue?vue&type=template&id=ceab81c6&scoped=true&"
import script from "./studentAttendance.vue?vue&type=script&lang=js&"
export * from "./studentAttendance.vue?vue&type=script&lang=js&"
import style0 from "./studentAttendance.vue?vue&type=style&index=0&id=ceab81c6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ceab81c6",
  null
  
)

export default component.exports