<template>
  <div class="content has_breadcrumb">
	<edoovo-table
	  height="100%"
	  ref="multipleTable"
	  :data="usersData.tableData"
	  rowKey="id"
	  :title="$route.query.className"
	  defaultValue="-"
	  class="list-tableBox"
	  :v-loading="usersData.isLoading"
	  @sortChange="_onSortChange"
	  :sort="usersData.sort"
	>
	  <!-- ===== -->
	  <!-- 空状态 -->
	  <!-- ===== -->
	  <div slot="empty" style="text-align: center">
	    <div
	      style="
	        width: 213px;
	        text-align: center;
	        border-radius: 50%;
	        margin-bottom: 12px;
	        line-height: 200px;
	      "
	    >
	      <img
	        style="width: 100%"
	        :src="require('@/assets/images/products/noData.svg')"
	      />
	    </div>
	    <p style="font-size: 22px">No student yet.</p>
	  </div>
	  <!-- ======= -->
	  <!-- 分页组件 -->
	  <!-- ======= -->
	  <edoovo-pagination
	    slot="pagination"
	    @size-change="_pageSizeChange"
	    @current-change="_pageChange"
	    :current-page="query.current"
	    :page-size="query.size"
	    :total="usersData.totalNum"
	  />
	  <!-- ====== -->
	  <!-- 筛选组件 -->
	  <!-- ====== -->
	  <div
	    v-if="filterRenderData.length"
	    class="list-filterBox"
	    slot="filter"
	  >
	    <base-filter
	      ref="tablefilter"
	      @filterCallback="_filterCallback"
	      :renderData="filterRenderData"
	      :multKeys="multKeys"
	    />
			
	  </div>
			
			
	  <!-- =========== -->
	  <!-- 每一项column -->
	  <!-- =========== -->
	<!--  <edoovo-table-column v-slot="{ row }" size="M" label="No.">
	    {{row.dataIndex+1}}
	  </edoovo-table-column> -->
	  <edoovo-table-column
	    size="L"
	    label="Students name"
	    prop="studentName"
	    sortName="Students name"
	    :getTooltipText="(row) => `${row.studentName}`"
	    v-slot="{ row }"
	  >
	    <div class="table-cell-box" @click="toDetail(row)">
				  <div  class="statusImg">
					 <head-img
					   class="table-cell-img"
					   :src="row.photo ? `${row.photo}` : ``"
					   
					   :firstName="`${row.studentFirstName}`"
					   :lastName="`${row.studentLastName}`"
					   line-height="32"
					   
					 /> 
				  </div>
	     
	      <span class="table-cell-name">{{ row.studentName }}</span>
	    </div>
	  </edoovo-table-column>
	  <edoovo-table-column
	    size="S"
	    label="Attendance"
	    prop="checkIn"
	    v-slot="{ row }"
		align='center'
	  >
	   <span v-if="row.attendance === 0">-</span>
	    <i
	      class="el-icon-circle-check"
	      style="font-size: 24px; color: #3db175"
	      v-if="row.attendance === 1"
				
	    ></i>
	    <i
	      class="el-icon-circle-close"
	      style="font-size: 24px; color: #ff7d72"
	      v-if="row.attendance === 2"
	    ></i>
	  </edoovo-table-column>
	  <edoovo-table-column
	    size="S"
	    label="Check in"
	    prop="checkIn"
	    v-slot="{ row }"
	    :getTooltipText="(row) => `${row.checkinTime?row.checkinTime:''}`"
	  >
	    {{ row.checkinTime || "-" }}
	  </edoovo-table-column>
	  <edoovo-table-column
	    size="M"
	    label="Temperature"
	    prop="checkinTemperature"
	    v-slot="{ row }"
	    :getTooltipText="(row) => `${row.checkinTemperature?row.checkinTemperature:'-'}`"
	  >
	    {{ row.checkinTemperature ? row.checkinTemperature+'°C': "-" }}
	  </edoovo-table-column>
			<edoovo-table-column
			  size="M"
			  label="In class"
			  prop="checkinTemperature"
			  v-slot="{ row }"
			  :getTooltipText="(row) => `${row.inClassTime?row.inClassTime:'-'}`"
			>
			  {{ row.inClassTime ? row.inClassTime: "-" }}
			</edoovo-table-column>
			<edoovo-table-column
			  size="M"
			  label="Temperature"
			  prop="checkinTemperature"
			  v-slot="{ row }"
			  :getTooltipText="(row) => `${row.inClassTemperature?row.inClassTemperature:'-'}`"
			>
			  {{ row.inClassTemperature ? row.inClassTemperature+'°C': "-" }}
			</edoovo-table-column>
	  <edoovo-table-column
	    size="S"
	    label="Check out"
	    prop="checkOut"
	    v-slot="{ row }"
	    :getTooltipText="(row) => `${row.checkoutTime}`"
	  >
	    {{ row.checkoutTime || "-" }}
	  </edoovo-table-column>
	  <edoovo-table-column
	    size="M"
	    label="Temperature"
	    prop="checkoutTemperature"
	    v-slot="{ row }"
	    :getTooltipText="(row) => `${row.checkoutTemperature}`"
	  >
	    <!-- {{ row.checkoutTemperature+'°C' || "-" }} -->
			  {{ row.checkoutTemperature? row.checkoutTemperature+'°C': "-" }}
	  </edoovo-table-column>
	 <edoovo-table-column
	   size="M"
	   label="Health check"
	   prop="type"
	   v-slot="{ row }"
		align='center'
	 		:tooltip='false'
	 >
	   <span v-if="row.healthCheckTime==0">-</span>
	   
	        		<el-tooltip popper-class="edoovo-table-cell-tooltip" :content="`${row.healthCheckTime>1? row.healthCheckTime+' health check records':'1 health check record'}`"	v-else				
	        		>
	        			<i
	        			  
	        			  class="icon iconfontNew icona-SymptomsIcon_24dp"
	        			  style="font-size: 24px; cursor: pointer"
	        			></i></el-tooltip>
	 </edoovo-table-column>
	 <edoovo-table-column
	   size="M"
	   label="Remarks"
	   prop="type"
	   align='center'
	   v-slot="{ row }"
	   :getTooltipText="(row) => `${row.remarks>1? row.remarks+' remarks':'1 remark'}`"
	 		:tooltip='false'
	 >
	   <span v-if="row.remarks==0">-</span>
	   
	        		<el-tooltip popper-class="edoovo-table-cell-tooltip" :content="`${row.remarks>1? row.remarks+' remarks':'1 remark'}`"	v-else
	        		><i
	     
	     class="icon iconfontNew icona-RemarkIcon_24dp"
	     style="font-size: 24px; cursor: pointer"
	   ></i></el-tooltip>
	 </edoovo-table-column>
	  <!-- <edoovo-table-column size="L"> </edoovo-table-column> -->
	  <!-- ====== -->
	  <!-- action -->
	  <!-- ====== -->
	 
	</edoovo-table>
	<Snackbar
	  :visible.sync="snackbarData.visible"
	  :content="snackbarData.content"
	/>
	<!-- dialog -->
	   
	<md-dialog-confirm
	  :md-active.sync="absentDialogVisible"
	  md-title="Mark as absent?"
	  md-content="The student's status will change from no attendance to absent."
	  md-confirm-text="Submit"
	  md-cancel-text="Cancel"
	  @md-cancel="() => {}"
	  @md-confirm="onSaveAbsent"
	/>
  </div>
</template>

<script>
import { Ajax, Utils } from "@/common";
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "AttendanceStudent",
  components: {
  },
  watch: {
	 
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
	isToday(){
		return this.nowDate==this.query.date
	}
  },
  data() {
    return {
		timeData: {},
      healthCheckList: [],
      chooseStudentItem: {},
      checkInDialogVisible: false,
      checkOutDialogVisible: false,
      temperatureDialogVisible: false,
      absentDialogVisible: false,
	  nowDate:"",
      query: {
        size: 20,
        current: 1,
        status: null,
        date: "",
        classId: "",
		schoolId:"",
		locationId:sessionStorage.getItem('locationId')||""
      },
      menuDatas: [
        {
          name: "Attendance",
          key: "0",
          active: true,
          children: [
            {
              name: "Daily",
              key: "",
            },
          ],
        },
      ],
      activeIndex: "",
      usersData: {
        ref: "usersTable",
        totalNum: 0,
        sort: { name: "", type: "" },
        isLoading: false,
        tableTitle: "Active Staff",
        tableData: [],
      },
      filterRenderData: [
        {   title: "Student name",filterKey: "studentName", type: "input" },
        {
          title: "Attendance",
          filterKey: "status",
          type: "radio",
          radioData: [
            { key: "Absent", value: 2 },
            { key: "Present", value: 1 },
          ],
        },
        // {
        //   title: "Symptoms",
        //   filterKey: "symptoms",
        //   type: "checkbox",
        //   checkboxData: [
        //     { key: "Cough", value: "Cough" },
        //     { key: "Runny Nose", value: "Runny Nose" }
        //   ]
        // }
      ],
      multKeys: [],
      snackbarData: {
        visible: false,
        content: "",
      },
    };
  },
  created() {
	  this.$emit('changeDate',"datePicker")
	  if(this.$route.query.classId){
		   this.query.classId = this.$route.query.classId; 
		   // this.query.schoolId = this.$route.query.schoolId;
	  }else{
		  this.query.schoolId = this.$route.query.schoolId;
	  }
 
	if(sessionStorage.getItem('date')){
		this.query.date = Utils.formatTime(sessionStorage.getItem('date'), '{y}-{m}-{d}')
	}else{
			this.query.date = Utils.formatTime(Date.now(), '{y}-{m}-{d}') 
	}
	this.nowDate=Utils.formatTime(Date.now(), '{y}-{m}-{d}') 
    this.filterRenderData.push({
      title: "Symptoms",
      filterKey: "symptoms",
      type: "checkbox",
      checkboxData: this.healthCheckList,
    });
    this.getData();
    this.queryHealthCheckList();
  },
 beforeDestroy() {
 	this.$emit('changeDate',"")
 }, 
  methods: {
	toDetail(row) {
			  let data = JSON.parse(sessionStorage.getItem("breadcrumb"))
			  data[1].name = "Classes"
				data[2].routeName = "classDetailInfo"
			 data[3] = {
			      name: `${row.studentName}`,
			    }
				
			  this.$store.commit("SET_BREADCRUMDATA", data);
	 const {href} = this.$router.resolve({
	    name: "classTimeline",
	    query: {
	      id: row.id,
	      studentId: row.studentId,
	      classId: this.$route.query.classId,
		  className:this.$route.query.className,
		  status:row.status,
			  date:row.date
	    },
	  });
	  window.open(href,'_blank')
	},
    onChangeTopSelect(val) {
      // this.getData(val);
    },
    onDateClosed(val) {
      let tim = Date.parse(val);
      this.query.date = Utils.formatTime(tim, "{y}-{m}-{d}");
      this.getData();
	  
    },
    onSelectedStudentItem(row, valueKey) {
      this[valueKey] = true;
      this.chooseStudentItem = row;
	  // console.log(this.chooseStudentItem)
    },
    onCheckIn(paramsDetail) {
      let params = {
        timeLine: paramsDetail,
      };
      Object.assign(params.timeLine, {
        checkInOutId: this.chooseStudentItem.id,
        operation: 0,
        operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        schoolId: this.user.locationId || this.chooseStudentItem.schoolId,
        studentId: this.chooseStudentItem.studentId,
        classId: this.$route.query.classId, // '06da6a3a6aab4b0fbc38b1af4815214b',
        checkTime: moment().format("DD/MM/YYYY hh:mm A"), // '10:00:00 AM',
         userType: paramsDetail.dropUserType=="pickup"?1:0,
		startDate:moment(moment().format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss'),
		endDate:moment(moment().format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
        // dropOffUser: '',
        // checkValue: '36.00',
        // symptoms: 'symptoms',
        // remarks: 'remarks'
      });
	  params.startDate = moment(moment().format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss')
	  params.endDate = moment(moment().format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
      Ajax.post("/attendance/checkInOut/checkIn", params).then((res) => {
        if (res.code === "0000") {
          this.checkInDialogVisible = false;
          this.snackbarData = { visible: true, content: res.message };
          this.getData();
        }
      });
    },
    onCheckOut(paramsDetail) {
      let params = {
        timeLine: paramsDetail,
      };
      Object.assign(params.timeLine, {
        checkInOutId: this.chooseStudentItem.id,
        operation: 0,
        operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        schoolId: this.user.locationId || this.chooseStudentItem.schoolId,
        studentId: this.chooseStudentItem.studentId,
        classId: this.$route.query.classId, // '06da6a3a6aab4b0fbc38b1af4815214b',
        checkTime: moment().format("DD/MM/YYYY hh:mm A"), // '10:00:00 AM',
         userType: paramsDetail.dropUserType=="pickup"?1:0,
        status: 1,
        // dropOffUser: '',
        // checkValue: '36.00',
        // symptoms: 'symptoms',
        // remarks: 'remarks'
      });
	  	  params.startDate = moment(moment().format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss')
	  	  params.endDate = moment(moment().format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
      Ajax.post("/attendance/checkInOut/checkOut", params).then((res) => {
        if (res.code === "0000") {
          this.checkOutDialogVisible = false;
          this.snackbarData = { visible: true, content: res.message };
          this.getData();
        }
      });
    },
	temperatureeditTime (e) {
		this.timeData = e
	},
    onAddTemperature(tempValue) {
		console.log(this.timeData)
		let time = moment().format("DD/MM/YYYY hh:mm A").split(' ')
		time[1] = `${this.timeData.hh}:${this.timeData.mm}`
		time[2] = this.timeData.state
      let params = {
        checkInOutId: this.chooseStudentItem.id,
        operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        temperature: tempValue.tempValue,
		temperatureType: this.timeData.states ? 3 : 4,
		symptomsList: tempValue.paramsDetail.symptomsList,
		remarks: tempValue.paramsDetail.remarks,
        time:time.join(' '), // '10:00:00 AM'
      };
	  // console.log(params)
	  // return false
      Ajax.post("/attendance/timeLine/addTempV1", params).then((res) => {
        if (res.code === "0000") {
          this.temperatureDialogVisible = false;
          this.snackbarData = { visible: true, content: res.message };
          this.getData();
        }
      });
    },
    onSaveAbsent(data) {
      let params = {
        operationUser: this.user.userId, // '3fa7f627dda84f3e914f79863e0eda0b'
        schoolId: this.user.locationId || this.chooseStudentItem.schoolId,
        studentId: this.chooseStudentItem.studentId,
        classId: this.$route.query.classId, // '06da6a3a6aab4b0fbc38b1af4815214b'
		absentTime:moment().format("DD/MM/YYYY hh:mm A"),
		startDate:moment(moment().format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm:ss'),
		endDate:moment(moment().format('YYYY-MM-DD')).utc().add(24, "hours").format("YYYY-MM-DD HH:mm:ss")
      };
      Ajax.post("/attendance/checkInOut/markAsAbsent", params).then((res) => {
        if (res.code === "0000") {
          this.absentDialogVisible = false;
          this.snackbarData = { visible: true, content: res.message };
          this.getData();
        }
      });
    },
    toTimelinePage(row) {
      this.$router.push({
        name: "attendanceStudentTimeline",
        query: {
          id: row.id,
          studentId: row.studentId,
          classId: this.$route.query.classId,
		  className:this.$route.query.className,
		  status:row.status
        },
      });
    },
    getData() {
      this.query.userId = this.user.userId;
	  let query = this.query
	  query.startDate = moment(this.query.date).utc().format('YYYY-MM-DD HH:mm:ss')
	  let url 
	  if(this.query.schoolId){
		  url =  "/attendance/checkInOut/getStudentListBySchoolIdEnhanceV1"
	  }else{
		  url = '/attendance/checkInOut/getStudentListByClassIdEnhanceV1'
	  }
      Ajax.post(
       url,
        query
      ).then((res) => {
        let { records, total } = res.data;
        this.usersData.tableData = records;
		this.usersData.totalNum = res.data.total
		// 0210cy table组件中$index不知道为什么每次都是1 研究了一会无果只能手动追加了 如有好办法删除下面的循环
		this.usersData.tableData.forEach((item,index)=>{
			if(item.checkinTime){
				item.checkinTime=moment.utc(item.checkinTime).local().format("h:mm A")
			}
			if(item.checkoutTime){
				item.checkoutTime=moment.utc(item.checkoutTime).local().format("h:mm A")
			}
			if(item.inClassTime){
				item.inClassTime=moment.utc(item.inClassTime).local().format("h:mm A")
			}
			item.dataIndex=index 
		})
      });
    },
    queryHealthCheckList() {
      this.healthCheckList.splice(0, this.healthCheckList.length);
      Ajax.post(`/usermanage/setting/getList`, {
        dataLevel: "healthCheck",
        dataType: "illness",
        userId: this.user.userId,
      }).then((res) => {
        res.data.forEach((item) => {
          item.key = item.value;
          this.healthCheckList.push(item);
        });
      });
      Ajax.post(`/usermanage/setting/getList`, {
        dataLevel: "healthCheck",
        dataType: "visualCheck",
        userId: this.user.userId,
      }).then((res) => {
        res.data.forEach((item) => {
          item.key = item.value;
          this.healthCheckList.push(item);
        });
      });
    },
    menuSelect(key, item) {
      this.activeIndex = key;
      this.query.status = key;
      this.usersData.tableTitle = item.name;
      this.getData();
    },
    _onSortChange(sort) {
      this.usersData.sort = sort;
      this.query.sortName = sort.name;
      this.query.sortType = sort.type;
      // console.log(sort)
      this.getData();
    },
    _pageChange(val) {
      this.query.current = val;
      this.getData();
    },
    _pageSizeChange(val) {
      this.query.size = val;
      this.getData();
    },
    _filterCallback(data) {
      let obj = {};
      data.filter((i) => {
        obj[i.filterKey] = i.filterVal;
      });
      this.query = {
        size: 20,
        current: 1,
        status: null,
        date: this.query.date,
        classId: this.$route.query.classId,
		locationId:sessionStorage.getItem('locationId')||""
      };
      Object.assign(this.query, obj);
      this.getData();
    },
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
 /deep/html .md-overlay {
  /* z-index: 9!important; */
}
/deep/.table-cell-img{
	/* padding: 3px; */
	img{
		border-radius: 50%;
	}
}

.statusImg{
	display: inline-block;
	    padding: 3px;
	    width: 38px;
	    border-radius: 50%;
		margin-right: 5px;
			/* background-color: #ddd; */
}
/deep/.previewImg{
	display: none;
}
/deep/.md-field .md-input, .md-field .md-textarea{
	 font-size: 14px;
 }
 /deep/.md-field label{
	  font-size: 14px;
 }
 /deep/.ggPage-main-left{
	 display: none;
 }
 /deep/.has_breadcrumb .ggPage-main {
  padding-top: 33px !important;
}
 
/deep/.edoovo-table-empty{
	padding-top: 80px;
}
/deep/.edoovo-table-box{
	
	padding-top: 136px!important;
	padding-bottom: 28px!important;
	
}
.content{
	height: 100%;
	/* padding-bottom: 60px; */
}
.content{
	position: relative;
	top:-24px; 
}
/deep/.edoovo-table-pagination{
	bottom: -24px;
}
</style>